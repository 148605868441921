.applications-list-narrow-tall-v4-wrapper {
    // background-color: rgba(189, 2, 2, 0.3);
    display: inline-block;
    height: 80vh;
    font-family: "din-2014", sans-serif;

    .applications-list-narrow-tall-v4-grid {
        margin: 20px;
        // background-color: rgba(189, 2, 2, 0.3);

        .applications-list-narrow-tall-v4-item {
            margin: 2px;
            padding: 15px;
            border: 1px solid #333;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            border: 1px solid white;
            height: 300px;
            width: 140px;
            cursor: pointer;
            text-align: center;
            position: relative;

            .image-wrapper {
                margin-bottom: 10px;
                text-align: center;

                img {
                    width: 100px;
                    height: auto;
                    filter: invert(0%);
                }
            }

            .appName {
                margin-top: 10px;
                font-size: 17px;
                font-weight: 600;
                margin-bottom: 0px;
                line-height: 1.2;
                text-transform: uppercase;
                letter-spacing: 1px;
            }

            .appHero {
                font-size: 12px;
                font-weight: normal;
                line-height: 1.3;
                margin-top: 11px;
                margin-bottom: 11px;
                text-transform: uppercase;
            }

            hr {
                border-top: 1px solid #00a7ff;
                width: 50%;
                margin-top: 12px;
                margin-bottom: 12px;
            }

            .appCopy {
                font-size: 12px;
                font-weight: normal;
            }

            .actions {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                button {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    background-color: white;
                    color: black;
                    font-size: 11px;
                    padding: 4px 12px;
                    font-family: "din-2014", sans-serif;
                    text-transform: uppercase;
                    border: 0;
                }
            }
        }

        .applications-list-narrow-tall-v4-item:hover {
            background-color: rgba(0, 0, 0, 0.65);
            color: white;
            border: 1px solid white;

            .image-wrapper img {
                filter: invert(0%);
            }
        }

        .applications-list-narrow-tall-v4-item.selected {
            &,
            &:hover {
                background-color: rgba(255, 255, 255, 0.9);
                border-color: white; // #fd9e00;
                color: #333;
                cursor: default;

                .image-wrapper img {
                    filter: invert(85%);
                }

                hr {
                    border-top-color: #fd9e00;
                }

                .actions {
                    button {
                        background-color: #fd9e00;
                        color: #fd9e00;
                    }
                }
            }
        }
    }
}
