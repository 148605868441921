// z-index: 1040;

// margin-top: -14px;
#wiki-icon-button {
  color: white;
  background-color: #fd9e00;
  border: 1px solid #462e0b;
  height: 32px;
  width: 32px;
  font-size: 22px;
  padding-left: 4px;
  line-height: 1;
  margin-right: 30px;

  &:hover {
    background-color: #ffb743;
    color: #ab6600;
  }

  &:active {
    background-color: #fcc061;
    color: #66430f;
  }

  &.logged-out {
    opacity: 0;
    cursor: default;
  }

  &.wiki-icon-liveline {
    margin: 0;
    top: auto;
    bottom: auto;
    padding: 0;
    width: auto;
    height: auto;
  }
}
