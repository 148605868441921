@import "../../../styles/materialdesign-var-colors.scss";
@import "../../../styles/materialdesign-var-color-defaults.scss";

$primary-light: $blue-200;
$primary-mid: $blue-400;
$primary-dark: $blue-800;

$success-light: $green-200;
$success-mid: $green-400;
$success-dark: $green-800;

$info-light: $purple-200;
$info-mid: $purple-400;
$info-dark: $purple-800;

$warning-light: $orange-200;
$warning-mid: $orange-400;
$warning-dark: $orange-600;

$danger-light: $red-200;
$danger-mid: $red-400;
$danger-dark: $red-800;

a.aim-button.material {
  &.a-primary {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $primary-mid;
    }
  }

  &.a-success {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $success-mid;
    }
  }

  &.a-info {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $info-dark;
    }
  }

  &.a-warning {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $orange-800;
    }
  }

  &.a-danger {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $danger-dark;
    }
  }
}

.aim-button.material {
  &.btn-primary {
    color: $white;
    border-color: $primary-mid;
    background-color: $primary-mid;
    &:before {
      background-color: $primary-mid;
    }
    &:hover {
      border-color: $primary-dark;
    }
    &:hover {
      background-color: $primary-dark;
      &:before {
        background-color: $primary-dark;
      }
    }
  }
  &.btn-success {
    color: $white;
    border-color: $success-mid;
    background-color: $success-mid;
    &:before {
      background-color: $success-mid;
    }
    &:hover {
      border-color: $success-dark;
    }
    &:hover {
      background-color: $success-dark;
      &:before {
        background-color: $success-dark;
      }
    }
  }
  &.btn-info {
    color: $white;
    border-color: $info-mid;
    background-color: $info-mid;
    &:before {
      background-color: $info-mid;
    }
    &:hover {
      border-color: $info-dark;
    }
    &:hover {
      background-color: $info-dark;
      &:before {
        background-color: $info-dark;
      }
    }
  }
  &.btn-warning {
    color: $white;
    border-color: $warning-mid;
    background-color: $warning-mid;
    &:before {
      background-color: $warning-mid;
    }
    &:hover {
      border-color: $warning-dark;
    }
    &:hover {
      background-color: $warning-dark;
      &:before {
        background-color: $warning-dark;
      }
    }
  }
  &.btn-danger {
    color: $white;
    border-color: $danger-mid;
    background-color: $danger-mid;
    &:before {
      background-color: $danger-mid;
    }
    &:hover {
      border-color: $danger-dark;
    }
    &:hover {
      background-color: $danger-dark;
      &:before {
        background-color: $danger-dark;
      }
    }
  }

  &.outline {
    &.btn-primary {
      color: $blue-600;
      border-color: $primary-mid;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $primary-mid;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $primary-mid;
        }
      }
    }
    &.btn-success {
      color: $green-600;
      border-color: $success-mid;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $success-mid;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $success-mid;
        }
      }
    }
    &.btn-info {
      color: $info-dark;
      border-color: $info-dark;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $info-dark;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $info-dark;
        }
      }
    }
    &.btn-warning {
      color: $orange-800;
      border-color: $orange-800;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $orange-800;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $orange-800;
        }
      }
    }
    &.btn-danger {
      color: $danger-dark;
      border-color: $danger-dark;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $danger-dark;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $danger-dark;
        }
      }
    }
  }
}
