.home-page.grid-original {
  &:before {
    content: "";
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-image: url(https://aip.aegion.com/Content/Images/dot-net-images/ag_bg_0.jpg);
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
  }

  &.local-host-side-nav-expanded {
    margin-left: 277px;
  }

  .hp-grid-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "hp-applications-list hp-application-details";
  }

  .hp-applications-list {
    grid-area: hp-applications-list;
    text-align: center;
  }

  .hp-application-details {
    grid-area: hp-application-details;
    text-align: left;
  }

  @media all and (-ms-high-contrast: none) {
    .hp-grid-container {
      display: -ms-grid;
      -ms-grid-columns: 2fr 1fr;
      -ms-grid-rows: 1fr;
    }

    .hp-applications-list {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }

    .hp-application-details {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
    }
  }
}
