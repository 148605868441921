.users header.navbar-default .navbar-nav > .dropdown > button {
	color: #959595;
	border: 0px solid white;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 1px;
	padding-top: 2px;
	padding-bottom: 2px;
	margin-right: 20px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.users header.navbar-default .navbar-nav > .dropdown > button > i.menu--icon {
	display: block;
	float: left;
	width: 35px;
	height: 20px;
	line-height: 20px;
	font-size: 21px;
	padding-right: 10px;
}

.users header.navbar-default .navbar-nav > .open > button,
header.navbar-default .navbar-nav > .open > button:hover,
header.navbar-default .navbar-nav > .open > button:focus {
	background-color: inherit;
	color: #555;
}
