#login-icon-button {
  color: white;
  background-color: #fd9e00;
  border: 1px solid #462e0b !important;
  border-radius: 0 !important;
  height: 32px;
  width: 76px;
  padding-left: 4px;
  line-height: 1;
  margin-right: 5px;

  &:hover {
    background-color: #fd9e00;
    color: #ab6600;
  }

  &:active {
    background-color: #fd9e00;
    color: #66430f;
  }
}
