.aegion-webchat-wrapper {
  display: inline-block;
  border: none !important;
  background-color: transparent;
  margin-right: 3px;

  .uwebchat-open-button {
    height: 32px !important;
    max-height: 32px !important;
    width: 32px !important;
    background-image: none !important;
    box-shadow: none !important;

    padding: 0 !important;
    font-size: 21px;
    background-color: rgb(240, 240, 240) !important;
    color: #777 !important;
    border: 1px solid #333 !important;
    border-radius: 0 !important;

    &:hover {
      color: #333 !important;
      background-color: rgb(230, 230, 230) !important;
    }

    &:active {
      background-color: rgb(255, 255, 255) !important;
    }
  }
}
