.customer-support-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;

  border: 1px solid transparent;
  .col-grow {
    flex: 1 1 auto;
    align-self: auto;
  }

  .col-static {
    flex: 0 1 auto;
    align-self: auto;
  }
}
