.schedule-free-trial-button {
    background-color: #fd9e00;
    color: white;
    border: 0 !important;
    text-transform: uppercase;
    padding: 10px 15px;

    &,
    &:visited,
    &:focus {
        text-decoration: none;
        color: white;
    }

    &:hover {
        text-decoration: none;
        background-color: #ffb743;
        color: #ab6600;
    }
}
