.aegion-webchat-wrapper {
  z-index: 1040;
  .uwebchat-open-button,
  .uwebchat-chat-popup {
    position: static !important;
  }
  &.webchat-is-open {
    .uwebchat-chat-popup {
      display: block !important;
      position: fixed !important;
      z-index: 20000 !important;
      top: auto !important;
      bottom: 55px !important;
      right: 50px !important;
      left: auto !important;
    }
  }
  &.webchat-is-closed {
    .uwebchat-open-button {
      display: block !important;
    }
    .uwebchat-chat-popup {
      display: none !important;
    }
  }
}

.aegion-webchat-wrapper.in-app-chat-cisv-overrides {
  .uwebchat-chat-popup {
    position: fixed !important;
    z-index: 20000 !important;
    top: 55px !important;
    bottom: auto !important;
    right: 50px !important;
    left: auto !important;
  }
}

.wiki-icon-liveline {
  display: none !important;
}

.aegion-webchat-wrapper.in-app-chat-liveline-overrides {
  margin-right: 20px;
}
