// ************************
// ORIGINAL COLOR REFERENCE
// ************************

// .btn-default,
// .btn-default:hover {
//   border-color: #ccc; // mid, border
// }
// .btn-default:before {
//   background: #f0f0f0; // light, background
// }
// .btn-default:hover {
//   color: #999; // dark, hover
// }

// .btn-primary,
// .btn-primary:hover {
//   border-color: #0f9fcf; // mid, border
// }
// .btn-primary:before {
//   background: #4fd0fb; // light, background
// }
// .btn-primary:hover {
//   color: #0f9fcf; // dark, (mid) hover
// }

// .btn-success,
// .btn-success:hover {
//   border-color: #0ac964; // mid, border
// }
// .btn-success:before {
//   background: #50f29c; // light, background
// }
// .btn-success:hover {
//   color: #0ac964; // dark, (mid) hover
// }

// .btn-info,
// .btn-info:hover {
//   border-color: #8078cc; // mid, border
// }
// .btn-info:before {
//   background: #b2ade4; // light, background
// }
// .btn-info:hover {
//   color: #8078cc; // dark, (mid) hover
// }

// .btn-warning,
// .btn-warning:hover {
//   border-color: #c19716; // mid, border
// }
// .btn-warning:before {
//   background: #ecc54e; // light, background
// }
// .btn-warning:hover {
//   color: #c19716; // dark, (mid) hover
// }

// .btn-danger,
// .btn-danger:hover {
//   border-color: #ab0505; // mid, border
// }
// .btn-danger:before {
//   background: #f45858; // light, background
// }
// .btn-danger:hover {
//   color: #ab0505; // dark, (mid) hover
// }

// ************************
// BEGIN CSS
// ************************

$white: #f0f0f0;
$black: #101010;
$black-light: #333;

$gray-light: #f0f0f0;
$gray-mid: #ccc;
$gray-dark: #999;

$blue-light: #4fd0fb;
$blue-mid: #0f9fcf;
$blue-dark: $blue-mid;

$green-light: #50f29c;
$green-mid: #0ac964;
$green-dark: $green-mid;

$purple-light: #b2ade4;
$purple-mid: #8078cc;
$purple-dark: $purple-mid;

$orange-light: #ecc54e;
$orange-mid: #c19716;
$orange-dark: $orange-mid;

$red-light: #f45858;
$red-mid: #ab0505;
$red-dark: $red-mid;

$default-light: $gray-light;
$default-mid: $gray-mid;
$default-dark: $gray-dark;

$primary-light: $blue-light;
$primary-mid: $blue-mid;
$primary-dark: $blue-dark;

$success-light: $green-light;
$success-mid: $green-mid;
$success-dark: $green-dark;

$info-light: $purple-light;
$info-mid: $purple-mid;
$info-dark: $purple-dark;

$warning-light: $orange-light;
$warning-mid: $orange-mid;
$warning-dark: $orange-dark;

$danger-light: $red-light;
$danger-mid: $red-mid;
$danger-dark: $red-dark;

.aim-button {
  i {
    margin-left: 5px;

    &.no-margin {
      margin: 0;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

a.aim-button {
  &.a-default,
  &.a-primary,
  &.a-success,
  &.a-info,
  &.a-warning,
  &.a-danger {
    cursor: pointer;
    &.underline-on-hover:hover {
      text-decoration: underline;
    }
    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }

  &.a-default {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $black-light;
    }
  }

  &.a-primary {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $primary-dark;
    }
  }

  &.a-success {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $success-dark;
    }
  }

  &.a-info {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $info-dark;
    }
  }

  &.a-warning {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $warning-dark;
    }
  }

  &.a-danger {
    &:hover {
      color: #999;
    }

    &,
    &:visited,
    &:active,
    &.underline-on-hover:hover {
      color: $danger-dark;
    }
  }
}

.aim-button {
  &.outline {
    &.btn-default {
      color: $black-light;
      border-color: $black-light;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $default-dark;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $default-dark;
        }
      }
    }
    &.btn-primary {
      color: $primary-dark;
      border-color: $primary-dark;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $primary-dark;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $primary-dark;
        }
      }
    }
    &.btn-success {
      color: $success-dark;
      border-color: $success-dark;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $success-dark;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $success-dark;
        }
      }
    }
    &.btn-info {
      color: $info-dark;
      border-color: $info-dark;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $info-dark;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $info-dark;
        }
      }
    }
    &.btn-warning {
      color: $warning-dark;
      border-color: $warning-dark;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $warning-dark;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $warning-dark;
        }
      }
    }
    &.btn-danger {
      color: $danger-dark;
      border-color: $danger-dark;
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
      &:hover {
        border-color: $danger-dark;
      }
      &:hover {
        background-color: $white;
        &:before {
          background-color: $danger-dark;
        }
      }
    }
  }
}
