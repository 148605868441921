.details-original-v2-wrapper {
    margin-top: 32px;
    min-width: 250px;
    max-width: 350px;
    color: white;

    .title {
        font-family: "din-condensed", sans-serif;
        font-weight: 300;
        letter-spacing: 1px;
        font-size: 47px;
        margin-bottom: 17px;
        text-transform: uppercase;
    }

    hr {
        border-top: 2px solid #00a7ff;
    }

    .body {
        font-family: "din-2014", sans-serif;
        line-height: 1.65;
        font-weight: 300;
        text-align: justify;
    }

    .actions {
        margin-top: 32px;
        text-align: center;

        button {
            background-color: #fd9e00;
            color: white;
            border: 0 !important;
            text-transform: uppercase;
            padding: 10px 15px;

            &:hover {
                background-color: #ffb743;
                color: #ab6600;
            }
        }
    }
}
