.vertical_nav {
	.menu--item {
		overflow: hidden;
		.menu--link {
			color: white;
			.left-app {
				float: left;
			}
			.left-app::before {
				margin-left: 0px;
				font-size: 36px;
			}
			.mini-title {
				opacity: 1;
				position: absolute;
				top: 30px;
				right: 1px;
				transition-property: opacity;
				transition-duration: 0.3s;
				transition-delay: 0.3s;
			}
		}
		.fa {
			font-size: 30px;
			margin-left: 12px;
			margin-top: 8px;
		}
	}
}
.menu--subitens__opened {
	.menu--link {
		.mini-title {
			opacity: 0 !important;
			transition-property: opacity;
			transition-duration: 0s !important;
			transition-delay: 0s !important;
		}
	}
}
