@media screen and (min-width: 1537px) {
    .home-page.grid-original-wmw-wvc-wrapper {
        position: fixed;
        height: 100vh;
        width: 100vw;

        .grid-original-wmw-wvc {
            overflow-y: auto;
            height: 100vh;

            .hp-grid-container {
                position: absolute;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
    }
}
