.request-more-info-button {
    background-color: #efefef;
    color: #333;
    border: 0 !important;
    text-transform: uppercase;
    padding: 10px 15px;
    margin-right: 15px;

    &,
    &:visited,
    &:focus {
        text-decoration: none;
        color: #333;
    }

    &:hover {
        text-decoration: none;
        background-color: #2db6ff;
        color: white;
    }
}
